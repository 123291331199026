import React from "react";

const Footer = () => {
    return (
        <div className="footer">
            v0.01
        </div>
    )
}

export default Footer